import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TeamList from "./screens/TeamList";
import TeamMember from "./screens/TeamMember";
import AddTeamMember from "./screens/AddTeamMember";
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<TeamList />} />
      <Route path="/team/:id" element={<TeamMember />} />
      <Route path="/team/:name" element={<TeamMember />} />
      <Route path="/add-member" element={<AddTeamMember />} />
    </Routes>
  </Router>
);

export default App;

