import React, { useState } from "react";
import { db, storage } from "../firebase";
import { collection, addDoc } from "firebase/firestore";
import {  ref,uploadBytes, getDownloadURL } from "firebase/storage";

const AddTeamMember = () => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (image) {
    const imageRef = ref(storage, `teamMembers/${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      await addDoc(collection(db, "teamMembers"), {
        name,
        role,
        description,
        imageUrl,
      });

      setName("");
      setRole("");
      setDescription("");
      setImage(null);
      alert("Team member added successfully!");
    } else {
      alert("Please upload an image!");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>Add Team Member</h1>
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Role"
        value={role}
        onChange={(e) => setRole(e.target.value)}
        required
      />
      <textarea
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        required
      />
      <input type="file" onChange={(e) => setImage(e.target.files[0])} required />
      <button type="submit">Add Member</button>
    </form>
  );
};

export default AddTeamMember;
