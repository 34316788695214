import React, { useEffect, useState } from "react";

import { collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";

import { db } from "../firebase";

const TeamList = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const fetchTeamMembers = async () => {
      const querySnapshot = await getDocs(collection(db, "teamMembers"));
      const members = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTeamMembers(members);
    };

    fetchTeamMembers();
  }, []);

  return (
    <div className="container mt-4">
    <div className="row">
      {teamMembers.map((member) => (
        <div className="col-md-4 mb-4" key={member.id}>
          <div className="card h-100">
            <img
              src={member.imageUrl}
              className="card-img-top"
              alt={member.name}
            />
            <div className="card-body">
              <h5 className="card-title">Name: {member.name}</h5>
              <p className="card-text">Title: {member.position}</p>
              <p className="card-text">Bio:{member.bio}</p>
              <Link to={`/team/${member.id}`}className="btn btn-primary">
                View Profile
              </Link>

              {/* <Link to={`/team/${member.name}`}className="btn btn-primary">
                View Profile2
              </Link> */}

            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
  );
};

export default TeamList;
