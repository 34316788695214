import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBNWMsKIYY2sejoeaF4ojfXiQgNVwGc-v0",
    authDomain: "gnteammembers.firebaseapp.com",
    projectId: "gnteammembers",
    storageBucket: "gnteammembers.firebasestorage.app",
    messagingSenderId: "438286487954",
    appId: "1:438286487954:web:0fe5dbdb315bdf13a6714b",
    measurementId: "G-VWRRKSBHCT"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
