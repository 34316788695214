import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const TeamMember = () => {
  const { id } = useParams();
  const params = useParams()

  console.log(params)
  const [member, setMember] = useState(null);

  useEffect(() => {
    const fetchMember = async () => {
      const docRef = doc(db, "teamMembers", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setMember(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchMember();
  }, [id]);

  if (!member) return <div>Loading...</div>;

  return (
    <div>
      <h1>{member?.name}</h1>
      <p>{member?.role}</p>
      <p>{member?.description}</p>
      <img src={member?.imageUrl} alt={`${member?.name}`} style={{ width: "200px" }} />
    </div>
  );
};

export default TeamMember;
